/* ScrollTrigger - to query the all DOM elements
   addObserver - a observer for monitoring if the element has reached 
   at certain point so that a class "active" will be added to the DOM for 
   trigger other activities
*/
const scrollTrigger = (selector, options = {} ) => {
    let els = document.querySelectorAll(selector)
    els = Array.from(els);
    els.forEach(el => {
        addObserver(el, options)
    })
}

const addObserver = (el, options) => {
    let observer = new IntersectionObserver((entries, observer) => {
        entries.forEach(entry => {
            if(entry.isIntersecting) {
                entry.target.classList.add('active')
                observer.unobserve(entry.target)
            }
        })
    }, options)
    observer.observe(el);
}

export {scrollTrigger, addObserver};