import React, {Component} from 'react';
import M from 'materialize-css/dist/js/materialize.min.js';

export default class Navbar extends Component {

  componentDidMount() {
    const elems = document.querySelectorAll('.sidenav');
    const instances = M.Sidenav.init(elems,  {
      edge: "left",
      inDuration: 250,
      outDuration: 200,
      draggable: true
    });
  }

  render() {
    return (
      <>
      <nav className="navbar">
        <div className="nav-wrapper">
          <a href="#" className="sidenav-trigger" data-target="mobile-nav">
            <i className="material-icons">menu</i>
          </a>
          <ul className="left hide-on-small-only">
            <li><a href="#hero">Home</a></li>
            <li><a href="#about">About</a></li>
            <li><a href="#projects">Projects</a></li>
            <li><a href="#footer">Contact</a></li>
          </ul>
        </div>
      </nav>

      <ul className="sidenav" id="mobile-nav">
        <li><a className="sidenav-close" href="#hero">Home</a></li>
        <li><a className="sidenav-close" href="#about">About</a></li>
        <li><a className="sidenav-close" href="#projects">Projects</a></li>
        <li><a className="sidenav-close"href="#footer">Contact</a></li>
      </ul>
      </>
    )
  }
}
