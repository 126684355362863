import React from 'react';
import ProjectImages from '../images';

const Project = (props) => {
  let projectImgKeys  = Object.keys(ProjectImages);
  let projectImg = ProjectImages.Default;
  let projectSpan = ""; // additional span 2 class
  let i = 1;
   
  const { projectCount, curProjectNum, projectData, projectDataimageAlt } = props;

    /* Checking the current project is on is either odd or even row */
    while(projectCount > 2 && i < (curProjectNum / 2)) {
      i++;
    }

    if (i % 2 === 1 && curProjectNum % 2 === 1) {   
      projectSpan = "card--span-2" // odd row and first position
    } else if (i % 2 === 0 && curProjectNum % 2 === 0) {
      projectSpan = "card--span-2" // even row and the second position
    } else if (curProjectNum === projectCount && projectCount % 2 === 1) {
      projectSpan = "card--span-3" // last row with only one element
    } // else occupy one span only
  

  projectImgKeys.forEach((key) => {    
    if (key === projectData.backgroundImage) { 
      projectImg = ProjectImages[key]
    };
  });


  return(
    <div className={`project--card ${projectSpan}`}>
      <a href={projectData.url} target="_blank" rel="noopener noreferrer">
        <div className={"project--card-bg card--content"} style={{ backgroundImage: `url(${projectImg})`}}>
          <img src={projectData.image} alt={projectDataimageAlt}/>
          <h4 className="z-depth-5">{projectData.title}</h4>
        </div>
      </a>
      <div className="project--card-description">{projectData.description}</div>
    </div>
  )
}

export default Project;
