import React from 'react';
import { faGithub, faLinkedinIn } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Footer = () => {
  return(
    <footer className="footer--wrapper" id="footer">
      <div className="footer--socialmedia">
        <div className="socialmedia--wrapper">
          <a href="https://github.com/grimeswong" target="_blank" rel="noopener noreferrer">
            <div className="socialmedia--icon">
              <FontAwesomeIcon className="icon--github" icon={faGithub} size="3x"/>
            </div>
          </a>
            <div className="socialmedia--descriptions">
              <p>Browse my <a href="https://github.com/grimeswong" target="_blank" rel="noopener noreferrer">Github</a> Repositories</p>
            </div>
        </div>
        <div className="socialmedia--wrapper">
          <a href="https://au.linkedin.com/in/grimes-wong-1b3534114" target="_blank" rel="noopener noreferrer">
            <div className="socialmedia--icon">
              <FontAwesomeIcon className="icon--linkedin" icon={faLinkedinIn} size="3x"/>
            </div>
          </a>
          <div className="socialmedia--descriptions">
            <p>View my <a href="https://au.linkedin.com/in/grimes-wong-1b3534114" target="_blank" rel="noopener noreferrer">LinkedIn</a> Account</p>
          </div>
        </div>
      </div>
      <div className="footer--copyright">Grimes Wong © copyright reserved 2020 -</div>
    </footer>
  )
}

export default Footer;
