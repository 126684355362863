import React from 'react';
import Project from './Project.js';
import { scrollTrigger } from "../utils/ScrollObserver.js";
import { faFilePowerpoint } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Projects = (props) => {
  let projectCount = Object.keys(props.projectsData.projects).length;
  scrollTrigger('.projects--wrapper', {rootMargin: '-40%'});  // when 40% visible is reached
  return (
    <section className="projects--wrapper" id="projects">
      <div className="projects--intro-wrapper ">
        <div className="projects--intro-text">
          <FontAwesomeIcon className="icon--javascript" icon={faFilePowerpoint} size="3x"/>
            <h2>Projects</h2>
        </div>
      </div>

      <div className="projects--intro-projects">

      {/* Loop through all the projects
        Don't use the reverse() to reserve list order as rendering will update twice, so the list will reserve back to original, use toReversed() instead
      */}
      
      {props.projectsData.projects.toReversed().map((project, index) => { 
        return (<Project key={index} projectData={project} projectCount={projectCount} curProjectNum={index+1}/>) 
      })}
      </div>
    </section> /* {project wrapper end} */
  )
}

export default Projects;
