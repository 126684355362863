

const ProjectImages = {
    Default: require('./mobile-hero-bg.png'),
    Tributepage: require('./project-tributepage.jpg'), 
    Reactdoc: require('./project-reactdoc.png'),
    ITtermstranslator: require('./project-ittermstranslator.png'), 
    Idumpling: require('./project-idumpling.png'),
    Plumblossombeauty: require('./project-plumblossombeauty.png'),
    Gamehub: require('./project-gamehub.png')
}

export default ProjectImages;