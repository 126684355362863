import React from 'react';

const Hero = (props) => {

  // create star and set attributes
  const createStars = () => {
    let stars = getDivState().height / 8 ;  // the mass of the stars
    for (let i=1; i<=stars; i++) { // create stars limit by screen size
      let star = document.createElement("div");
      star.className = "hero--star";
      let sizeOfStar = getRanNum(1, 2);
      star.style.width = sizeOfStar + "px";
      star.style.height = sizeOfStar + "px";
      star.style.left= getRanNum(0, getDivState().width - 15) + "px"; /* or use window.innerWidth - document.documentElement.clientWidth to get the scroll bar width)*/
      star.style.top= getRanNum(0, getDivState().height - 15) + "px"; /* deduct -15 in case the clientwidth of preloader without scroll bar */
      star.style["animation-duration"] = getRanNum(3, 8) + "s";
      document.getElementsByClassName("hero--stars")[0].appendChild(star);
    }
  }

  // remove stars and recreate a "div" of stars
  const removeStars = () => {
    document.getElementsByClassName("hero--stars")[0].remove();
    let stars = document.createElement("div");
    stars.className = "hero--stars";
    document.getElementsByClassName("hero--wrapper")[0].appendChild(stars);
  }

  // get the current div width and height
  // can be adjusted to screen or window size
  const getDivState = () => {
      let currentState = { width: props.width,
                           height: props.height
                         }
    return currentState;
  }

  // get a proper random number
  const getRanNum = (min, max) => {
    let draw;
    do {
      draw = Math.floor(Math.random() * max);
    } while (draw > max || draw < min)
    return draw;
  }


  // logic for decide the current client width and height whether is updated and suitable
  if(props.width !== 0 && props.height !== 0) {
    removeStars();  // for avoid stars were build and rebuild again
    if(props.width >= 576) {  // Will not generate any star for mobile
      createStars();
    }
  }

  return (
      <section className="hero--wrapper " id="hero">
        <h1 className="hero--h1"></h1>
        <div className="hero--stars">
          {/* generate by this component */}
        </div>
      </section>
  );
}

export default Hero;
