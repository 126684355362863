import React from 'react';
import { Helmet } from 'react-helmet';
import Footer from './Footer.js';

const Layout = (props) => {
  return (
    <>
    <Helmet>
      <meta charset="utf-8" />
      <meta
        name="description"
        content="Grimes Wong | Web / Application Developer"
      />
      <meta
        name="keywords"
        content="Grimes Wong, Front-end Developer, Web Developer, Application Developer, Web & Application Development"
      />
      <meta
        name="author"
        content="Grimes Wong"
      />
    <title>Grimes Wong | Front-end Web Developer</title>
    </Helmet>
    {props.children}
    <Footer />
    </>
  );
}

export default Layout;
