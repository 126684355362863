import React from 'react';
import avatar from '../images/Profile.jpg';
import gatsby from '../images/gatsby.png';
import { faPhp, faJsSquare, faReact, faWordpress } from "@fortawesome/free-brands-svg-icons";
import { faDatabase} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

class About extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      offsetTop: 0,
      scrolled: false
    }
  }

  componentDidMount() {
    this.updateOffsetTop();
    window.addEventListener('scroll', this.updateScrolling);
    window.addEventListener('resize', this.playAnimation);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.updateScrolling);
    window.removeEventListener('resize', this.playAnimation);
  }

  updateScrolling = () => {
    if(this.state.offsetTop <= window.pageYOffset && this.state.scrolled === false) {
      this.setState({
        scrolled: true
      })
      this.playAnimation();
      window.removeEventListener('scroll', this.updateScrolling); // remove listener of tracking this scrolling
    }
  }

  updateOffsetTop = () => {
    const element = document.getElementsByClassName("about--wrapper")[0];
    if (element.offsetTop !== "undefined" && element.offsetTop >= 1) {
      this.setState({
        offsetTop: element.offsetTop
      })
    };
  }

  // Function for start to play animation that by adding animation class
  playAnimation = () => {
    if(document.documentElement.clientWidth >= 992) {
      this.addClass("about--right-lighteffect", "light--brighten-up");
      this.addClass("about--right-bg", "intro--brighten-up");
      this.addClass("about--right-wrapper", "introtext--show-up");
    }
    if(document.documentElement.clientWidth < 992) {
      this.removeClass("about--right-lighteffect", "light--brighten-up");
    }
    this.addClass("avatar--wrapper", "avatar--slide-left");
    this.addClass("avatar--text", "avatartext--slide-left");
    this.addClass("about--left-section", "bg--fade-out");
    this.addClass("about--specialization", "specialization--slide-up");
  }

  // Function for adding class to HTMLDOM
  addClass = (className, addAnimationClass) => {
    const element = document.getElementsByClassName(className)[0];
    element.classList.add(addAnimationClass);
  }

  // Function for remove class to HTMLDOM
  removeClass = (className, removeAnimationClass) => {
    const element = document.getElementsByClassName(className)[0];
    element.classList.remove(removeAnimationClass);
  }

  render() {
    return(
      <section className="about--wrapper" ><span id="about"></span>
        <div className="row about--bg">
          <div className="about--left-section col s12 l4">
            <div className="about--left-wrapper">
              <div className="about--avatar">
                <div className="avatar--wrapper">
                  <img src={avatar} alt="Grimes Avatar"/>
                </div>
                <div className="avatar--text">
                  <p className="avatar--text-name">Grimes Wong</p>
                  <p className="avatar--text-title">Web | Application Developer</p>
                </div>
              </div>
              <div className="about--specialization">
                <p className="specialization--text">Work with tool of</p>
                <div className="specialization--icons">
                  <div className="specialization--icon">
                    <FontAwesomeIcon className="icon--javascript" icon={faJsSquare} size="4x"/>
                    <p className="icon--text">Java Script</p>
                  </div>
                  <div className="specialization--icon">
                    <FontAwesomeIcon className="icon--react" icon={faReact} size="4x"/>
                    <p className="icon--text">React</p>
                  </div>
                  <div className="specialization--icon">
                    <img src={gatsby} alt="Gatsby" />
                    <p className="icon--text">Gatsby</p>
                  </div>
                  <div className="specialization--icon">
                    <FontAwesomeIcon className="icon--wordpress" icon={faWordpress} size="4x"/>
                    <p className="icon--text">Wordpress</p>
                  </div>
                  <div className="specialization--icon">
                    <FontAwesomeIcon className="icon--css3" icon={faPhp} size="4x"/>
                    <p className="icon--text">PHP</p>
                  </div>
                  <div className="specialization--icon">
                    <FontAwesomeIcon className="icon--database" icon={faDatabase} size="4x"/>
                    <p className="icon--text">SQL</p>
                  </div>

                </div>
              </div>
            </div>
          </div>
          <div className="about--right-section col s12 l8">
            <div className="about--right-bg"></div>
            <div className="about--right-lighteffect"></div>
            <div className="about--right-wrapper text-box">
              <p>A web / application developer who is passionate about changing world technology, not only constantly develop and refine technical skills through the diverse projects and self-study, but also actively seek enhanced solutions and absorb knowledge quickly for adapting this face-paced digital era that drives to become a successful all-round developer.</p>
            </div>
          </div>
        </div>
      </section>
    )
  }
}

export default About;
