import React from 'react';

const Preloader = (props) => {
  let removePreloaderBg = props.status===true ? "bg-fade-out" : null;
  let removePreloader = props.status===true ? "fade-out" : null
  return (
    <div className={`preloader--bg ${removePreloaderBg}`}>
      <div className={`preloader ${removePreloader}`}>
        <div className="preloader-wrapper active">
          <div className="spinner-layer spinner-blue-only">
          <div className="circle-clipper left">
            <div className="circle"></div>
          </div><div className="gap-patch">
            <div className="circle"></div>
          </div><div className="circle-clipper right">
            <div className="circle"></div>
          </div>
        </div>
        </div>
      </div>
    </div>
  )
}

export default Preloader;
