import React from 'react';
import Layout from './components/Layout.js';
import Preloader from './components/Preloader.js';
import Navbar from './components/Navbar.js';
import Hero from './components/Hero.js';
import About from './components/About.js';
import Projects from './components/Projects.js';
import projectsData from './data/projects.json';
import './styles/main.scss';


class App extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      width: 0,
      height: 0,
      allMounted: false
    }
  }

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
    this.updateallMounted();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }

  updateWindowDimensions = () => {
    this.setState({
      width: document.documentElement.clientWidth, // or use window.innerWidth and subtruct the width of scroll bar
      height: document.documentElement.clientHeight
    });
  }

  updateallMounted = () => {
    this.setState({
      allMounted: true
    })
    document.body.style.overflow = "visible";
  }


  render() {
    return (
      <div className="App">
        <Layout >
          <main>
            <Preloader status={this.state.allMounted}/>
            <Hero width={this.state.width} height={this.state.height} />
            <Navbar />
            <About />
            <Projects projectsData={projectsData}/>
          </main>
        </Layout>
      </div>
    );
  }
}

export default App;
